import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "./Sidebar";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import KeyIcon from "@mui/icons-material/Key";
import axios from "axios";
import { BASE_URL } from "../../Constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import {
  API_KEY_BLANK_ERROR_MESSAGE,
  FETCH_ERROR,
} from "../../utils/Validation";
import { getToken } from "../../utils/utils";

const useStyles = makeStyles((themes) => ({
  MainContainer: {
    display: "flex",
    width: "100%",
    height: "93vh",
    background: "#F1F1F1",
    alignContent: "flex-start",
  },
  Breadcrumbs: {
    display: "flex",
    gap: 4,
    alignItems: "flex-start",
    marginBottom: "20px",
    width: "100%",
    paddingLeft: 20,
    paddingTop: 20,
    height: 40,
  },
  BreadcrumbsText: {
    color: theme.palette.secondary.black,
    fontFamily: "Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "30px",
  },
  BreadcrumbsIcon: {
    width: "24px",
    height: "24px",
  },
  tableContainer: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
  },
  secondaryText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.secondary.black,
  },
  gridContainer: {
    padding: "20px",
    display: "block !important",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [key, setKey] = useState("");

  const handleApiKeyChange = (e) => {
    setKey(e.target.value);
  };
  const token = getToken();
  const fetchOpenApi = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}/config/openai_key`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setKey(response.data.config_value);
    } catch (err) {
      console.log(err);
      toast.error(FETCH_ERROR);
    }
  }, [token]);
  const handleApiKeYUpdate = async () => {
    if (!key || key.trim() === "") {
      toast.error(API_KEY_BLANK_ERROR_MESSAGE);
      return;
    }
    try {
      const response = await axios.put(
        `${BASE_URL}/config`,
        {
          config_key: "openai_key",
          config_value: key.trim(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message);
      fetchOpenApi();
    } catch (err) {
      console.log(err);
      toast.error(FETCH_ERROR);
    }
  };
  useEffect(() => {
    fetchOpenApi();
  }, [fetchOpenApi]);
  return (
    <>
      <Header />
      <Box className={classes.MainContainer}>
        <Sidebar />
        <Grid container spacing={1} className={classes.gridContainer}>
          <Box className={classes.Breadcrumbs}>
            <Typography className={classes.BreadcrumbsText}>
              Settings
            </Typography>
          </Box>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: "4px", padding: "10px" }}>
              <CardContent>
                <Stack direction={"row"} spacing={2}>
                  <KeyIcon />
                  <Typography variant="h2">Add API Key (OpenAI)</Typography>
                </Stack>

                <TextField
                  variant="outlined"
                  value={key}
                  fullWidth
                  sx={{ my: 2 }}
                  placeholder="Add OpenAI API Key"
                  label="API Key"
                  onChange={handleApiKeyChange}
                />
                <Button variant="outlined" onClick={handleApiKeYUpdate}>
                  Update
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </>
  );
};

export default Settings;
