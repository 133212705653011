import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, CssBaseline, Grid } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: '1px solid #3E3E3D33'
  },
  profileImg: {
    height: "32px",
    width: "32px",
  },
  ImgContainer: {
    marginRight: "20px",
    marginTop: "10px",
  },
}));
export default function Header() {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Grid container className={classes.root}>
        <Box></Box>
        <Box></Box>
      </Grid>
    </>
  );
}
