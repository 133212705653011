// src/Login.js
import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CssBaseline,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundImage from "../assets/altlegal.png";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import CustomLoader from "../utils/CustomLoader";

import { checkEmail } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
  },
  imageSection: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#fff",
    padding: theme.spacing(2),
  },
  formSection: {
    padding: theme.spacing(10),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
    maxWidth: "400px",
  },
  button: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  radioButton: {
    backgroundColor: theme.palette.secondary.main,
    width: "100px",
    height: "40px",
    borderRadius: "4px",
  },
  secondaryTexts: {
    color: theme.palette.secondary.black,
  },
  linkText: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  linkSecondaryText: {
    color: theme.palette.secondary.black,
  },
  bottomContainer: {
    textAlign: "center",
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.main,

  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "50%",
}));

const Login = () => {
  const classes = useStyles();
  const [role, setRole] = useState("Admin");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const handleChangeEmail = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (value !== "") {
      setIsValidEmail(checkEmail(value));
    } else {
      setIsValidEmail(true);
    }
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value === "") {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
  };

  const handleLogin = async () => {
    if (!isValidEmail) {
      toast.error("Invalid email address");
      setIsLoading(false);
      return;
    }
    if (password === "") {
      setIsValidPassword(false);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    try {
      const response = await axios.post(`${BASE_URL}/user/login/`, formData);
      if (response) {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("role", response.data.user_role);
        setPassword("");
        setUsername("");
        navigate("/admin/dashboard");
      } else {
        throw new Error("Response Error");
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
        toast.error(`${err?.response?.data?.detail}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12} md={6} className={classes.imageSection}></Grid>
        <Grid item xs={12} md={6} className={classes.formSection}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="left"
                variant="h1"
                fontWeight={700}
                gutterBottom
              >
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="left"
                gutterBottom
                className={classes.secondaryTexts}
              >
                Please enter your credentials below to continue
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl
                component="fieldset"
                fullWidth
                margin="normal"
                className={classes.formControl}
              >
                <RadioGroup
                  row
                  aria-label="role"
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <FormControlLabel
                    value="Staff"
                    className={classes.radioButton}
                    control={<CustomRadio />}
                    label="Staff"
                  />
                  <FormControlLabel
                    value="Admin"
                    className={classes.radioButton}
                    control={<CustomRadio />}
                    label="Admin"
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email"
                name="username"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={handleChangeEmail}
                placeholder="Enter your email address"
                className={classes.textField}
                error={!isValidEmail}
                helperText={!isValidEmail && "Invalid email address"}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                placeholder="Enter your password"
                autoComplete="current-password"
                value={password}
                onChange={handleChangePassword}
                className={classes.textField}
                error={!isValidPassword}
                helperText={!isValidPassword && "Password is required"}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleLogin}
                className={classes.button}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CustomLoader loading={isLoading} color="FFF" size={25} />
                ) : (
                  <>Login</>
                )}
              </Button>
            </Grid>
            <Box className={classes.bottomContainer}>
              <Link className={classes.linkText} to={"/register"}>
                <span className={classes.linkSecondaryText}>
                  Don't have an account?
                </span>{" "}
                Create Account
              </Link>
            </Box>
          </Grid>
        </Grid>
        <ToastContainer />
      </Grid>
    </>
  );
};

export default Login;
