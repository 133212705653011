export const FETCH_ERROR = "Error fetching data";
export const USER_DISABLED_MESSAGE = "User Disabled";
export const USER_ENABLED_MESSAGE = "User Enabled";
export const FILE_DOWNLOAD_SUCCESS_MESSAGE = "File downloaded successfully";
export const FILE_UPLOAD_ERROR_MESSAGE = "Error uploading file";
export const FILE_UPLOAD_SUCCESS_MESSAGE = "File uploaded successfully";
export const FILE_UPLOAD_REQUIRED_MESSAGE = "Please upload a file";
export const PROMPT_EDIT_SUCCESS_MESSAGE = "Prompt edited successfully";
export const PROMPT_DELETE_SUCCESS_MESSAGE = "Prompt deleted successfully";
export const PROMPT_CREATE_SUCCESS_MESSAGE = "Prompt created successfully";
export const TYPE_SELECTION_ERROR_MESSAGE = "Please select a Type";
export const PROMPT_FIELD_REQUIRED_MESSAGE = "Prompt field is required";
export const STATUS_SELECTION_ERROR_MESSAGE = "Please select a Status";
export const API_KEY_BLANK_ERROR_MESSAGE = "API Key can't be blank";