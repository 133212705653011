export const BASE_URL = "https://api.altlegal.metafic.co";
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// export const BASE_URL = "http://13.127.90.29:8000"
export const TEMP_BASE_URL = "https://api.altlegal.metafic.co";

export const FILE_CATEGORY = {
  it: "Public tenders for IT services provision",
  distribution: "Public tenders for distribution and leasing services",
};
