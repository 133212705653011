import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "./Sidebar";
import { Box, Typography, Button, Backdrop } from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../Constants/Constants";
import axios from "axios";
import { getToken } from "../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import CustomLoader from "../../utils/CustomLoader";
import {
  FETCH_ERROR,
  USER_DISABLED_MESSAGE,
  USER_ENABLED_MESSAGE,
} from "../../utils/Validation";

const useStyles = makeStyles((themes) => ({
  MainContainer: {
    display: "flex",
    background: "#F1F1F1",
    width: "100%",
  },
  ContentContainer: {
    height: "93vh",
    width: "80%",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    background: "#fff",
  },
  Breadcrumbs: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    marginBottom: "20px",
  },
  BreadcrumbsText: {
    color: theme.palette.secondary.black,
    fontFamily: "Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "30px",
  },
  BreadcrumbsIcon: {
    width: "24px",
    height: "24px",
  },
  tableContainer: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
  },
  BatchActionButton: {
    backgroundColor: "#F3F7F9 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "180px",
    height: "30px",
    padding: "7px 8.75px",
    gap: "10px",
    borderRadius: "3px 0px 0px 0px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    opacity: "0.65",
    marginBottom: "30px !important",
    textTransform: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [UserData, setUserData] = useState([]);
  const token = getToken();

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/user/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [token]);
  useEffect(() => {
    getData();
  }, [token, getData]);

  const handleDisable = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.put(`${BASE_URL}/user/disable/${id}`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        getData();
        toast.success(USER_DISABLED_MESSAGE);
      }
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  const handleEnable = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.put(`${BASE_URL}/user/enable/${id}`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        getData();
        toast.success(USER_ENABLED_MESSAGE);
      }
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const classes = useStyles();

  const rows = UserData?.map((item) => {
    return {
      id: item.id,
      email: item.email,
      role: item.user_role,
      isActive: item.is_active ? "Active" : "Inactive",
    };
  });
  const columns = [
    { field: "id", headerName: "Id", width: 90 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "tableHeaderCell",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      headerClassName: "tableHeaderCell",
      renderCell: (params) => {
        return (
          <span style={{ textTransform: "capitalize" }}>{params.value}</span>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      headerClassName: "tableHeaderCell",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          {params.row.isActive !== "Active" ? (
            <Button
              variant="outlined"
              color="success"
              size="small"
              sx={{ ml: 1, textTransform: "capitalize" }}
              onClick={() => handleEnable(params.row.id)}
            >
              Enable
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              sx={{ ml: 1, textTransform: "capitalize" }}
              onClick={() => handleDisable(params.row.id)}
            >
              Disable
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Header />
      <Box className={classes.MainContainer}>
        <Sidebar />
        <Box className={classes.ContentContainer}>
          <Box className={classes.Breadcrumbs}>
            <Typography className={classes.BreadcrumbsText}>Users</Typography>
          </Box>
          <Box className={classes.tableContainer}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              disableRowSelectionOnClick
              autoHeight
              disableSelectionOnClick
              classes={{
                columnHeader: classes.tableHeaderCell,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CustomLoader loading={isLoading} size={50} />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default Users;
