import { makeStyles } from "@mui/styles";
import React from "react";
import { Box, Button, Typography } from "@mui/material";
const useStyles = makeStyles((themes) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 10,
    width: "20vw !important",
    padding: "20px",
    height: "90vh",
  },
  Infocontainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "20px",
  },
  LogoWithHeading: {
    display: "flex",
    gap: 10,
  },
  LogoWithText: {
    fontFamily: "Helvetica !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    lineHeight: "24px !important",
    color: "#3E3E3D",
  },
  LogoWithParagraph: {
    fontFamily: "Helvetica !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
    color: "#3E3E3D",
  },
  RightSidebarLogoImg: {
    width: "100%",
    height: "100%",
  },
  subCategoryHead: {
    textAlign: "center",
    fontSize: "1rem !important",
    fontWeight: "bold !important",
  },
  subCategory: {
    textAlign: "center",
    fontSize: "1rem !important",
  },
}));

export default function RightSidebar({
  getSubCategory,
  handleExportDocs,
  metaData,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.Infocontainer}>
        <Box className={classes.LogoWithHeading}>
          <img
            src={require("../../assets/family_star.png")}
            width={"24px"}
            height={"24px"}
            alt="star"
          />
          <Typography className={classes.LogoWithText}>Alt Ai</Typography>
        </Box>
        <Box className={classes.LogoWithHeading}>
          <img
            src={require("../../assets/info.png")}
            width={"18px"}
            height={"18px"}
            alt="star"
          />
          <Typography className={classes.LogoWithParagraph}>
            Please add a document to analyze with Alt-Ai.
          </Typography>
        </Box>
      </Box>
      <Box>
        <img
          className={classes.RightSidebarLogoImg}
          src={require("../../assets/RightSidebarDataLogo.png")}
          width={"267px"}
          height={"267px"}
          alt="logoforrightsidebar"
        />
      </Box>
      <Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={getSubCategory}
        >
          AI Analyze
        </Button>
        {metaData && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="info"
            className={classes.button}
            onClick={handleExportDocs}
            sx={{ marginTop: 3 }}
          >
            Export
          </Button>
        )}
      </Box>
    </Box>
  );
}
