// src/App.js
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./hooks/useAuth";
import Login from "./pages/Login";
import AdminDashboard from "./pages/admin/Dashboard";
import Directory from "./pages/admin/Directory";
import Register from "./pages/Register";
import Settings from "./pages/admin/Settings";
import Prompts from "./pages/admin/Prompts";
import Users from "./pages/admin/Users";

const App = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (token !== null) {
      navigate("/admin/dashboard");
    }
  }, [token]);

  return (
    <>
      <AuthProvider>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/directory" element={<Directory />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/prompts" element={<Prompts />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
