import { Logout, PeopleAltOutlined } from "@mui/icons-material";
import {
  Box,
  CssBaseline,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh",
    width: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRight: "1px solid #3E3E3D33",
    overflow: "hidden",
    flexShrink: 0,
    padding: theme.spacing(2),
  },
  ListContainer: {
    width: "100%",
    height: "44px",
    padding: "10px 0px",
  },
  ListText: {
    fontFamily: "Helvetica !important",
    fontSize: "1rem !important",
    fontWeight: 400,
    lineHeight: "18.4px !important",
    opacity: "50%",
  },
  ListActiveText: {
    fontFamily: "Helvetica !important",
    fontSize: "1.2rem !important",
    fontWeight: 400,
    lineHeight: "18.4px !important",
    textAlign: "left",
    opacity: "100%",
  },
  ActiveDot: {
    width: "10px",
    height: "10px",
    background: "#C91719",
    borderRadius: "20px",
    textAlign: "center",
  },
  logoContainer: {
    textAlign: "center",
    padding: "20px 0",
  },
  logo: {
    width: "76px",
    height: "68.9px",
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(
    location.pathname.split("/")[2]
  );
  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const handleListItemClick = (tabName) => {
    if (tabName === "logout") {
      localStorage.removeItem("token");
      navigate("/login", { replace: true });
    } else {
      setSelectedIndex(tabName);
      navigate(`/admin/${tabName}`);
    }
  };
  return (
    <>
      <CssBaseline />
      <Box className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton
            onClick={() => handleListItemClick("dashboard")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              <img
                src={require("../../assets/document_scanner.png")}
                alt="menu-item"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Analyze Document"
              classes={{
                primary:
                  selectedIndex === "dashboard"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "dashboard" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton>
          <ListItemButton
            onClick={() => handleListItemClick("directory")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              <img
                src={require("../../assets/lists.png")}
                alt="menu-item"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Directory"
              classes={{
                primary:
                  selectedIndex === "directory"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "directory" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton>
          {/* <ListItemButton
            onClick={() => handleListItemClick("history")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              <img
                src={require("../../assets/history.png")}
                alt="menu-item"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="History"
              classes={{
                primary:
                  selectedIndex === "history"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "history" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton> */}



          {role === "admin" ? (
           <>
          <ListItemButton
            onClick={() => handleListItemClick("users")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              {/* <img
                src={require("../../assets/settings.png")}
                alt="menu-item"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              /> */}
              <PeopleAltOutlined/>
            </ListItemIcon>
            <ListItemText
              primary="Users"
              classes={{
                primary:
                  selectedIndex === "users"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "users" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton>

          <ListItemButton
            onClick={() => handleListItemClick("settings")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              <img
                src={require("../../assets/settings.png")}
                alt="menu-item"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              classes={{
                primary:
                  selectedIndex === "settings"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "settings" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton>
          <ListItemButton
            onClick={() => handleListItemClick("prompts")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              <img
                src={require("../../assets/family_star.png")}
                alt="menu-item"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Prompts"
              classes={{
                primary:
                  selectedIndex === "prompts"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "prompts" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton>
          </> 
          ):<>
          </>}
          <ListItemButton
            onClick={() => handleListItemClick("logout")}
            className={classes.ListContainer}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              classes={{
                primary:
                  selectedIndex === "logout"
                    ? classes.ListActiveText
                    : classes.ListText,
              }}
            />
            {selectedIndex === "logout" && (
              <Typography className={classes.ActiveDot}></Typography>
            )}
          </ListItemButton>
        </List>
        <Box className={classes.logoContainer}>
          <img
            src={require("../../assets/logo.png")}
            alt="Logo"
            className={classes.logo}
          />
        </Box>
      </Box>
    </>
  );
}
