// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C91618",
    },
    secondary: {
      main: "#F1F1F1",
      black: "#3E3E3D",
    },
  },
  typography: {
    fontFamily: "Helvetica, Arial, sans-serif",

    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: "1rem",
    },
  },
});

export default theme;
