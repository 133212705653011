import ClipLoader from "react-spinners/ClipLoader";
import React from "react";
import theme from "../theme";

export default function CustomLoader({
  color = theme.palette.primary.main,
  loading = true,
  size = 150,
}) {
  return (
    <ClipLoader
      color={color}
      loading={loading}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}