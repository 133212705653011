// src/Login.js
import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Radio,
  CssBaseline,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundImage from "../assets/altlegal.png";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import CustomLoader from "../utils/CustomLoader";

import { checkEmail } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
  },
  imageSection: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#fff",
    padding: theme.spacing(2),
  },
  formSection: {
    padding: theme.spacing(10),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: "100%",
    maxWidth: "400px",
  },
  button: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  radioButton: {
    backgroundColor: theme.palette.secondary.main,
    width: "100px",
    height: "40px",
    borderRadius: "4px",
  },
  secondaryTexts: {
    color: theme.palette.secondary.black,
  },
  linkText: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  linkSecondaryText: {
    color: theme.palette.secondary.black,
  },
  bottomContainer: {
    textAlign: "center",
    mt: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const Register = () => {
  const classes = useStyles();
  const [role, setRole] = useState("user");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleChangeEmail = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (value !== "") {
      setIsValidEmail(checkEmail(value));
    } else {
      setIsValidEmail(true);
    }
  };
  const handleRegister = async () => {
    if (!isValidEmail) {
      toast.error("Invalid email address");
      setIsLoading(false);
      return;
    }
    if (password === "") {
      setIsValidPassword(false);
      setIsLoading(false);
      return;
    }
    if (confirmPassword !== password) {
      toast.error("Password not match");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const formData = { email: username, password: password, user_role: role };
      const response = await axios.post(`${BASE_URL}/user/register/`, formData);
      if (response) {
        toast.success("Registration Successful");
        navigate("/login");
      }
    } catch (err) {
      toast.error("Something wrong, try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12} md={6} className={classes.imageSection}></Grid>
        <Grid item xs={12} md={6} className={classes.formSection}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="left"
                variant="h1"
                fontWeight={700}
                gutterBottom
              >
                Register
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="left"
                gutterBottom
                className={classes.secondaryTexts}
              >
                Please enter your credentials below to continue
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={handleChangeEmail}
                placeholder="Enter your email"
                className={classes.textField}
                error={!isValidEmail}
                helperText={!isValidEmail && "Invalid email address"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                placeholder="Enter your password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
                error={!isValidPassword}
                helperText={!isValidPassword && "Password is required"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmpassword"
                label="Confirm Password"
                type="password"
                id="ConfirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={classes.textField}
                error={!isValidPassword}
                helperText={!isValidPassword && "Password is required"}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleRegister}
                className={classes.button}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CustomLoader loading={isLoading} color="FFF" size={25} />
                ) : (
                  <>Register</>
                )}
              </Button>
            </Grid>
          </Grid>
          <Box className={classes.bottomContainer}>
            <Link className={classes.linkText} to={"/login"}>
              <span className={classes.linkSecondaryText}>
                Already have a account?
              </span>{" "}
              Login
            </Link>
          </Box>
        </Grid>
        <ToastContainer />
      </Grid>
    </>
  );
};

export default Register;
